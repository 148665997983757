import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

import Layout from '@components/Layout';
import FormattedMessage from '@components/FormattedMessage';
import AppDevelopmentHero from '@sections/service/Service.Hero';
import AppDevelopmentIntroduction from '@sections/service/Service.Introduction';
import AppDevelopmentFeatures from '@sections/service/Service.Features';
import AppDevelopmentServices from '@sections/service/Service.Services';
import AppDevelopmentCTA from '@sections/CTA/CTA.Main';

import '../components/Utils';

import { useThemeUI } from 'theme-ui';


// import Icons from '@icons';
import AccessAccount from '@icons/undraw/AccessAccount.Icon';

import MobileWireframe from '@icons/undraw/MobileWireframe.Icon';
import WorkTogether from '@icons/undraw/WorkTogether.Icon';
import ReactIcon from '@icons/undraw/React.Icon';
// import CodeTyping from '@icons/undraw/CodeTyping.Icon';
import ScrumBoard from '@icons/undraw/ScrumBoard.Icon';
import ModernDesign from '@icons/undraw/ModernDesign.Icon';
import Programming from '@icons/undraw/Programming.Icon';

import AWSLogo from '@icons/logo/AWS.Icon';
import DigitalOceanLogo from '@icons/logo/DigitalOcean.Icon';
import DockerLogo from '@icons/logo/Docker.Icon';
import ElasticLogo from '@icons/logo/Elastic.Icon';
// import FlutterLogo from '@icons/logo/Flutter.Icon';
// import IonicLogo from '@icons/logo/Ionic.Icon';
import NodeJsLogo from '@icons/logo/NodeJs.Icon';
import RedisLogo from '@icons/logo/Redis.Icon';
import ReactLogo from '@icons/logo/React.Icon';

const AppDevelopmentPage: React.FC = () => {
    const intl = useIntl();
    const invertHeader = true;

    const context = useThemeUI();
    const { theme } = context;
    const fillColor = theme.colors.gradientBg;

    const HeroTranslations = {
        title: {
            text: intl.formatMessage({ id: 'app-title' }),
        },
        subtitle: {
            text: intl.formatMessage({ id: 'app-subtitle' }),
        },
        description: {
            text: intl.formatMessage({ id: 'app-description' }),
        },
        Link1: {
            text: intl.formatMessage({ id: 'app-button-discover' }),
            link: '/app-development/#work'
        },
        Link2: {
            text: intl.formatMessage({ id: 'app-button-contact' }),
            link: '/contact/'
        },
        MainIcon: {
            // Icon: Goals
            // Icon: MobileMarketing
            // Icon: SocialDashboard
            Icon: AccessAccount,
            fill: fillColor,
        }
    }

    const IntroductionTranslations = {
        title: {
            // text: intl.formatMessage({ id: 'app-introduction-title' })
            text: <FormattedMessage message="app-introduction-title" />,
        },
        // description: {
        //     text: intl.formatMessage({ id: 'app-introduction-description' })
        // }
        bigLogo: true,
        logos: [
            { Icon: DockerLogo },
            { Icon: ReactLogo },
            { Icon: ElasticLogo },
            { Icon: NodeJsLogo },
            { Icon: AWSLogo },
            { Icon: DigitalOceanLogo },
            { Icon: RedisLogo },
        ]
    }

     const FeatureTranslations = {
        wave: false,
        columns: 3,
        title: {
            text: intl.formatMessage({ id: 'app-features-title' })
        },
        subtitle: {
            text: intl.formatMessage({ id: 'app-features-subtitle' })
        },
        features: [
            {
                title: intl.formatMessage({ id: 'app-features-1' }),
                description: intl.formatMessage({ id: 'app-features-1-desc' }),
                Icon: MobileWireframe,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'app-features-2' }),
                description: intl.formatMessage({ id: 'app-features-2-desc' }),
                Icon: ModernDesign,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'app-features-3' }),
                description: intl.formatMessage({ id: 'app-features-3-desc' }),
                Icon: WorkTogether,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'app-features-4' }),
                description: intl.formatMessage({ id: 'app-features-4-desc' }),
                Icon: ScrumBoard,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'app-features-5' }),
                description: intl.formatMessage({ id: 'app-features-5-desc' }),
                Icon: ReactIcon,
                fill: fillColor,
            },
            {
                title: intl.formatMessage({ id: 'app-features-6' }),
                description: intl.formatMessage({ id: 'app-features-6-desc' }),
                // Icon: CodeTyping,
                Icon: Programming,
                fill: fillColor,
            },
        ],
    };

    const ServicesTranslations = {
        title: {
            text: intl.formatMessage({ id: 'app-services-title' })
        },
        typeformButton: {
            text: intl.formatMessage({ id: 'app-services-button' }),
        },
        services: [
            { 
                title: { text: intl.formatMessage({ id: 'app-services-1' }) },
                description: { text: intl.formatMessage({ id: 'app-services-1-desc' }) },
                Image: { name: 'pwa-application.jpg' },
            },
            { 
                title: { text: intl.formatMessage({ id: 'app-services-2' }) },
                description: { text: <FormattedMessage message="app-services-2-desc" /> },
                Image: { name: 'pwa-desk.jpg' },
            },
            { 
                title: { text: intl.formatMessage({ id: 'app-services-3' }) },
                description: { text: intl.formatMessage({ id: 'app-services-3-desc' }) },
                Image: { name: 'man-programming.jpg' },
            },
        ]
    };

    return (
    <Layout id="app" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: 'app-meta-title' })}
            description={intl.formatMessage({ id: 'app-meta-description'})}
            slug='/app-development/'
        />
        <AppDevelopmentHero translations={ HeroTranslations } />
        <AppDevelopmentIntroduction translations={ IntroductionTranslations } sectionContext={{ small: true }} />
        <AppDevelopmentFeatures translations={ FeatureTranslations } />
        <AppDevelopmentServices translations={ ServicesTranslations } sectionContext={{ small: true, noPaddingTop: true, noPaddingBottom: true, }} />
        <AppDevelopmentCTA sectionContext={{ small: true, gradientBg: false }} />
        {/*<AppDevelopmentStrategy sectionContext={{ gradientBg: true, }}/>*/}
    </Layout>
    );
};

export default AppDevelopmentPage;
