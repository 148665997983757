import React from "react";

import { Icon } from '@types';

const ElasticIcon: Icon = ({ fill = "white", ...props }) => (
    <svg { ...props } version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 687.5 235.7" style={{enableBackground: 'new 0 0 687.5 235.7'}} xmlSpace="preserve">
    {/*<style type="text/css">
        .st0{fill:#FFFFFF;}
        .st1{fill:#FED10A;}
        .st2{fill:#24BBB1;}
        .st3{fill:#EF5098;}
        .st4{fill:#17A8E0;}
        .st5{fill:#93C83E;}
        .st6{fill:#0779A1;}
    </style>*/}
    <g>
        <g>
            <path style={{fill: '#FFFFFF'}} d="M236.6,123.5c0-19.8-12.3-37.2-30.8-43.9c0.8-4.2,1.2-8.4,1.2-12.7C207,30,177,0,140.2,0
                C118.6,0,98.6,10.3,86,27.7c-6.2-4.8-13.8-7.4-21.7-7.4c-19.6,0-35.5,15.9-35.5,35.5c0,4.3,0.8,8.5,2.2,12.4
                C12.6,74.8,0,92.5,0,112.2c0,19.9,12.4,37.3,30.9,44c-0.8,4.1-1.2,8.4-1.2,12.7c0,36.8,29.9,66.7,66.7,66.7
                c21.6,0,41.6-10.4,54.1-27.8c6.2,4.9,13.8,7.6,21.7,7.6c19.6,0,35.5-15.9,35.5-35.5c0-4.3-0.8-8.5-2.2-12.4
                C223.9,160.9,236.6,143.2,236.6,123.5"/>
            <path style={{fill: '#FED10A'}} d="M93,101.5l51.8,23.6L197,79.3c0.8-3.8,1.1-7.5,1.1-11.5c0-32.2-26.2-58.4-58.4-58.4
                c-19.3,0-37.2,9.5-48.1,25.4l-8.7,45.1L93,101.5z"/>
            <path style={{fill: '#24BBB1'}} d="M39.4,156.3c-0.8,3.8-1.1,7.7-1.1,11.7c0,32.3,26.3,58.5,58.6,58.5c19.4,0,37.5-9.6,48.4-25.6l8.6-44.9
                l-11.5-22l-52-23.7L39.4,156.3z"/>
            <path style={{fill: '#EF5098'}} d="M39.1,66.7l35.5,8.4l7.8-40.3c-4.8-3.7-10.8-5.7-17-5.7c-15.4,0-28,12.5-28,28C37.4,60.4,38,63.7,39.1,66.7"
                />
            <path style={{fill: '#17A8E0'}} d="M36,75.2C20.2,80.4,9.1,95.6,9.1,112.3c0,16.3,10.1,30.8,25.2,36.6l49.8-45L75,84.4L36,75.2z"/>
            <path style={{fill: '#93C83E'}} d="M154.3,200.9c4.9,3.7,10.8,5.8,16.9,5.8c15.4,0,28-12.5,28-28c0-3.4-0.6-6.7-1.7-9.7l-35.4-8.3L154.3,200.9z"
                />
            <path style={{fill: '#0779A1'}} d="M161.5,151.4l39,9.1c15.9-5.2,26.9-20.4,26.9-37.2c0-16.2-10.1-30.8-25.2-36.5l-51,44.7L161.5,151.4z"/>
        </g>
        <g>
            <path d="M330.8,165.5l4.7-0.5l0.3,9.6c-12.4,1.7-23,2.6-31.8,2.6c-11.7,0-20-3.4-24.9-10.2c-4.9-6.8-7.3-17.4-7.3-31.7
                c0-28.6,11.4-42.9,34.1-42.9c11,0,19.2,3.1,24.6,9.2c5.4,6.1,8.1,15.8,8.1,28.9l-0.7,9.3h-53.8c0,9,1.6,15.7,4.9,20
                c3.3,4.3,8.9,6.5,17,6.5C314.2,166.5,322.4,166.2,330.8,165.5z M326.4,130.2c0-10-1.6-17.1-4.8-21.2c-3.2-4.1-8.4-6.2-15.6-6.2
                c-7.2,0-12.7,2.2-16.3,6.5c-3.6,4.3-5.5,11.3-5.6,20.9H326.4z"/>
            <path d="M355.6,176.7V69.4h12.2v107.3H355.6z"/>
            <path d="M444.9,118.8v41.1c0,4.1,10.1,3.9,10.1,3.9l-0.6,10.8c-8.6,0-15.7,0.7-20-3.4c-9.8,4.3-19.5,6.1-29.3,6.1
                c-7.5,0-13.2-2.1-17.1-6.4c-3.9-4.2-5.9-10.3-5.9-18.3c0-7.9,2-13.8,6-17.5c4-3.7,10.3-6.1,18.9-6.9l25.6-2.4v-7
                c0-5.5-1.2-9.5-3.6-11.9c-2.4-2.4-5.7-3.6-9.8-3.6l-32.1,0V92.5h31.3c9.2,0,15.9,2.1,20.1,6.4
                C442.8,103.1,444.9,109.8,444.9,118.8z M394.7,152c0,10,4.1,15,12.4,15c7.4,0,14.7-1.2,21.8-3.7l3.7-1.3v-26.9l-24.1,2.3
                c-4.9,0.4-8.4,1.8-10.6,4.2C395.7,144,394.7,147.5,394.7,152z"/>
            <path d="M492.6,103.4c-11.8,0-17.8,4.1-17.8,12.4c0,3.8,1.4,6.5,4.1,8.1c2.7,1.6,8.9,3.2,18.6,4.9c9.7,1.7,16.5,4,20.5,7.1
                c4,3,6,8.7,6,17.1c0,8.4-2.7,14.5-8.1,18.4c-5.4,3.9-13.2,5.9-23.6,5.9c-6.7,0-29.2-2.5-29.2-2.5l0.7-10.6
                c12.9,1.2,22.3,2.2,28.6,2.2c6.3,0,11.1-1,14.4-3c3.3-2,5-5.4,5-10.1c0-4.7-1.4-7.9-4.2-9.6c-2.8-1.7-9-3.3-18.6-4.8
                c-9.6-1.5-16.4-3.7-20.4-6.7c-4-2.9-6-8.4-6-16.3c0-7.9,2.8-13.8,8.4-17.6c5.6-3.8,12.6-5.7,20.9-5.7c6.6,0,29.6,1.7,29.6,1.7
                v10.7C509.4,104.3,499.5,103.4,492.6,103.4z"/>
            <path d="M583,104.8h-25.9v39c0,9.3,0.7,15.5,2,18.4c1.4,2.9,4.6,4.4,9.7,4.4l14.5-1l0.8,10.1c-7.3,1.2-12.8,1.8-16.6,1.8
                c-8.5,0-14.3-2.1-17.6-6.2c-3.3-4.1-4.9-12-4.9-23.6v-42.9h-11.6V94.2H545V69.2h12.1v24.9H583V104.8z"/>
            <path d="M600,83.7V69.6h12.2v14.2H600z M600,176.7V94.2h12.2v82.5H600z"/>
            <path d="M665.2,92.5c3.6,0,9.7,0.7,18.3,2l3.9,0.5l-0.5,9.9c-8.7-1-15.1-1.5-19.2-1.5c-9.2,0-15.5,2.2-18.8,6.6
                c-3.3,4.4-5,12.6-5,24.5c0,11.9,1.5,20.2,4.6,24.9c3.1,4.7,9.5,7,19.3,7l19.2-1.5l0.5,10.1c-10.1,1.5-17.7,2.3-22.7,2.3
                c-12.7,0-21.5-3.3-26.3-9.8c-4.8-6.5-7.3-17.5-7.3-33c0-15.5,2.6-26.4,7.8-32.6C644.3,95.7,653,92.5,665.2,92.5z"/>
        </g>
    </g>
    </svg>

);

export default ElasticIcon;
