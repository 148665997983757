import React from "react";

import { Icon } from '@types';

const RedisIcon: Icon = ({ fill = "white", ...props }) => (
    <svg { ...props } baseProfile="tiny" height="1451.34px" id="Layer_1" version="1.2" viewBox="0 0 1451.34 1451.34" width="1451.34px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d="M1381.691,1058.94c-76.136,39.689-470.554,201.855-554.521,245.624  c-83.974,43.803-130.616,43.376-196.949,11.664c-66.333-31.713-486.059-201.251-561.667-237.402  c-37.793-18.051-57.659-33.302-57.659-47.7V886.913c0,0,546.428-118.956,634.649-150.607  c88.218-31.647,118.815-32.789,193.887-5.286c75.082,27.503,523.964,108.488,598.158,135.662c0,0-0.033,128.801-0.033,142.167  C1437.566,1023.115,1420.448,1038.76,1381.691,1058.94z" fill="#A42122" />
        <path d="M1381.691,914.694c-76.136,39.673-470.554,201.856-554.521,245.624  c-83.974,43.786-130.616,43.359-196.949,11.646C563.88,1140.27,144.163,970.698,68.555,934.579  c-75.609-36.135-77.193-61.017-2.92-90.092c74.265-29.092,491.689-192.868,579.918-224.52  c88.209-31.634,118.816-32.794,193.887-5.283c75.079,27.499,467.139,183.56,541.335,210.735  C1454.979,852.611,1457.828,875.004,1381.691,914.694z" fill="#D82F27" />
        <path d="M1381.691,823.782c-76.136,39.689-470.554,201.856-554.521,245.642  c-83.974,43.769-130.616,43.343-196.949,11.629c-66.342-31.695-486.059-201.25-561.667-237.385  c-37.793-18.067-57.659-33.285-57.659-47.684V651.746c0,0,546.428-118.95,634.649-150.602  c88.218-31.643,118.815-32.794,193.887-5.291c75.082,27.499,523.964,108.476,598.158,135.655c0,0-0.033,128.799-0.033,142.183  C1437.566,787.941,1420.448,803.584,1381.691,823.782z" fill="#A42122" />
        <path d="M1381.691,679.523c-76.136,39.685-470.554,201.852-554.521,245.637  c-83.974,43.785-130.616,43.343-196.949,11.646C563.88,905.111,144.163,735.535,68.555,699.409  c-75.609-36.131-77.193-61.005-2.92-90.102C139.9,580.233,557.324,416.453,645.553,384.81  c88.209-31.643,118.816-32.794,193.887-5.291c75.079,27.498,467.139,183.543,541.335,210.727  C1454.979,617.437,1457.828,639.837,1381.691,679.523z" fill="#D82F27" />
        <path d="M1381.691,579.889c-76.136,39.685-470.554,201.861-554.521,245.645  c-83.974,43.785-130.616,43.343-196.949,11.647C563.88,805.484,144.163,635.91,68.555,599.783  c-37.793-18.067-57.659-33.298-57.659-47.687v-144.23c0,0,546.428-118.951,634.649-150.594  c88.218-31.652,118.815-32.794,193.887-5.292c75.082,27.499,523.964,108.476,598.158,135.655c0,0-0.033,128.8-0.033,142.174  C1437.566,544.052,1420.448,559.691,1381.691,579.889z" fill="#A42122" />
        <path d="M1381.691,435.634c-76.136,39.685-470.554,201.86-554.521,245.642  c-83.974,43.773-130.616,43.342-196.949,11.643c-66.333-31.706-486.059-201.259-561.667-237.39  c-75.609-36.132-77.193-61.014-2.92-90.102C139.9,336.339,557.324,172.58,645.553,140.929  c88.209-31.652,118.816-32.794,193.887-5.287c75.079,27.503,467.139,183.548,541.335,210.731  C1454.979,373.544,1457.828,395.956,1381.691,435.634z" fill="#D82F27" />
        <polygon fill="#FFFFFF" points="911.195,296.749 787.571,309.578 759.89,376.168 715.19,301.863 572.443,289.033 678.956,250.62   647.003,191.659 746.72,230.657 840.743,199.877 815.328,260.85 " />
        <polygon fill="#FFFFFF" points="752.509,619.808 521.786,524.113 852.398,473.375 " />
        <ellipse cx="432.636" cy="402.254" fill="#FFFFFF" rx="176.704" ry="68.495" />
        <polygon fill="#791514" points="1057.292,314.947 1252.957,392.274 1057.456,469.517 " />
        <polygon fill="#AD2524" points="840.825,400.575 1057.292,314.947 1057.456,469.517 1036.235,477.818 " />
    </svg>
);

export default RedisIcon;
