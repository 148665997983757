import React from "react";

import { Icon } from '@types';

const DigitalOceanIcon: Icon = ({ fill = "white", ...props }) => (
    <svg { ...props } version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 354 354" style={{enableBackground: 'new 0 0 354 354'}} xmlSpace="preserve">
    {/*<style type="text/css">
        .st0{fill:#0080FF;}
        .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#0080FF;}
    </style>*/}
    <g id="XMLID_229_">
        <g id="XMLID_690_">
            <g id="XMLID_691_">
                <g>
                    <g id="XMLID_44_">
                        <g id="XMLID_48_">
                            <path id="XMLID_49_" style={{fill:'#0080FF'}} d="M177,221.5l0-34.2c36.2,0,64.3-35.9,50.4-74C222.3,99.3,211,88,196.9,82.9
                                c-38.1-13.8-74,14.2-74,50.4c0,0,0,0,0,0l-34.1,0c0-57.7,55.8-102.7,116.3-83.8c26.4,8.3,47.5,29.3,55.7,55.7
                                C279.7,165.7,234.7,221.5,177,221.5z"/>
                        </g>
                        <polygon id="XMLID_47_" style={{fillRule: 'evenodd', clipRule: 'evenodd',fill: '#0080FF'}} points="177.1,187.5 143,187.5 143,153.4 143,153.4 177.1,153.4 177.1,153.4                     "/>
                        <polygon id="XMLID_46_" style={{fillRule: 'evenodd', clipRule: 'evenodd',fill: '#0080FF'}} points="143,213.6 116.9,213.6 116.9,213.6 116.9,187.5 143,187.5 143,213.6                     "/>
                        <path id="XMLID_45_" style={{fillRule: 'evenodd', clipRule: 'evenodd',fill: '#0080FF'}} d="M116.9,187.5H95c0,0,0,0,0,0v-21.9c0,0,0,0,0,0h21.9c0,0,0,0,0,0V187.5z"/>
                    </g>
                </g>
            </g>
        </g>
        <g id="XMLID_234_">
            <path id="XMLID_677_" style={{fill:'#0080FF'}} d="M31.2,256.1c-4.4-3.1-10-4.6-16.4-4.6H0.8V296h14.1c6.4,0,12-1.6,16.4-4.9
                c2.4-1.7,4.3-4.1,5.7-7.1c1.3-3,2-6.6,2-10.5c0-3.9-0.7-7.4-2-10.4C35.6,260.1,33.7,257.7,31.2,256.1z M9,259h4.4
                c4.9,0,8.9,1,12,2.9c3.4,2.1,5.1,6,5.1,11.6c0,5.8-1.7,9.9-5.1,12.1h0c-2.9,1.9-6.9,2.9-11.9,2.9H9V259z"/>
            <path id="XMLID_676_" style={{fill:'#0080FF'}} d="M48.7,250.9c-1.4,0-2.5,0.5-3.5,1.4c-0.9,0.9-1.4,2-1.4,3.4c0,1.4,0.5,2.5,1.4,3.5
                c0.9,0.9,2.1,1.4,3.5,1.4c1.3,0,2.5-0.5,3.5-1.4c0.9-0.9,1.4-2.1,1.4-3.5c0-1.4-0.5-2.5-1.4-3.4C51.2,251.4,50,250.9,48.7,250.9z"
                />
            <rect id="XMLID_675_" x="44.7" y="264.6" style={{fill:'#0080FF'}} width="7.9" height="31.4"/>
            <path id="XMLID_670_" style={{fill:'#0080FF'}} d="M81.3,267.2c-2.4-2.1-5-3.4-7.9-3.4c-4.4,0-8,1.5-10.8,4.5c-2.8,2.9-4.3,6.7-4.3,11.3
                c0,4.4,1.4,8.2,4.2,11.2c2.8,2.9,6.5,4.4,10.8,4.4c3,0,5.7-0.8,7.8-2.5v0.7c0,2.6-0.7,4.6-2.1,6c-1.4,1.4-3.3,2.1-5.7,2.1
                c-3.6,0-5.9-1.4-8.7-5.2l-5.4,5.2l0.1,0.2c1.2,1.6,2.9,3.2,5.3,4.7c2.3,1.5,5.3,2.3,8.8,2.3c4.7,0,8.5-1.4,11.3-4.3
                c2.8-2.9,4.2-6.7,4.2-11.4v-28.5h-7.8V267.2z M79.2,285.8c-1.4,1.6-3.2,2.3-5.4,2.3c-2.3,0-4-0.8-5.4-2.3c-1.4-1.6-2-3.6-2-6.1
                c0-2.6,0.7-4.6,2-6.2c1.3-1.6,3.2-2.3,5.4-2.3c2.3,0,4,0.8,5.4,2.3c1.4,1.6,2.1,3.7,2.1,6.2C81.3,282.2,80.6,284.2,79.2,285.8z"/>
            <rect id="XMLID_668_" x="95.8" y="264.6" style={{fill:'#0080FF'}} width="7.9" height="31.4"/>
            <path id="XMLID_660_" style={{fill:'#0080FF'}} d="M99.8,250.9c-1.4,0-2.5,0.5-3.5,1.4c-0.9,0.9-1.4,2-1.4,3.4c0,1.4,0.5,2.5,1.4,3.5
                c0.9,0.9,2.1,1.4,3.5,1.4c1.3,0,2.5-0.5,3.5-1.4c0.9-0.9,1.4-2.1,1.4-3.5c0-1.4-0.5-2.5-1.4-3.4
                C102.3,251.4,101.2,250.9,99.8,250.9z"/>
            <path id="XMLID_652_" style={{fill:'#0080FF'}} d="M121,256.1h-7.8v8.5h-4.5v7.2h4.5v13c0,4.1,0.8,7,2.4,8.7c1.6,1.7,4.5,2.5,8.5,2.5
                c1.3,0,2.6,0,3.8-0.1l0.4,0v-7.2l-2.7,0.1c-1.9,0-3.1-0.3-3.7-1c-0.6-0.7-0.9-2.1-0.9-4.2v-11.9h7.4v-7.2H121V256.1z"/>
            <rect id="XMLID_642_" x="165.4" y="251.4" style={{fill:'#0080FF'}} width="7.9" height="44.6"/>
            <path id="XMLID_448_" style={{fill:'#0080FF'}} d="M253.1,284.8c-1.4,1.6-2.9,3-4,3.7v0c-1.1,0.7-2.5,1.1-4.1,1.1c-2.3,0-4.2-0.8-5.7-2.6
                c-1.5-1.7-2.3-4-2.3-6.6c0-2.7,0.8-4.9,2.3-6.6c1.5-1.7,3.4-2.6,5.7-2.6c2.5,0,5.2,1.6,7.5,4.3l5.2-5l0,0
                c-3.4-4.4-7.7-6.5-12.9-6.5c-4.3,0-8.1,1.6-11.2,4.7c-3.1,3.1-4.6,7-4.6,11.7s1.6,8.6,4.6,11.7c3.1,3.1,6.8,4.7,11.2,4.7
                c5.7,0,10.3-2.5,13.5-7L253.1,284.8z"/>
            <path id="XMLID_445_" style={{fill:'#0080FF'}} d="M285.6,269c-1.1-1.6-2.6-2.8-4.5-3.7c-1.9-0.9-4.1-1.4-6.5-1.4c-4.4,0-8,1.6-10.7,4.8
                c-2.6,3.2-4,7.1-4,11.8c0,4.8,1.5,8.7,4.3,11.7c2.9,3,6.7,4.5,11.4,4.5c5.3,0,9.7-2.2,13-6.4l0.2-0.2l-5.2-5l0,0
                c-0.5,0.6-1.2,1.2-1.8,1.8c-0.8,0.7-1.5,1.3-2.3,1.7c-1.2,0.6-2.5,0.9-4,0.9c-2.2,0-4-0.6-5.4-1.9c-1.3-1.2-2.1-2.8-2.3-4.8h20.9
                l0.1-2.9c0-2-0.3-4-0.8-5.8C287.5,272.3,286.7,270.6,285.6,269z M268.3,276.4c0.4-1.5,1.1-2.8,2.1-3.7c1.1-1.1,2.5-1.6,4.1-1.6
                c1.9,0,3.4,0.5,4.4,1.6c0.9,1,1.5,2.2,1.6,3.7H268.3z"/>
            <path id="XMLID_442_" style={{fill:'#0080FF'}} d="M315.9,267L315.9,267c-2.4-2-5.7-3.1-9.8-3.1c-2.6,0-5.1,0.6-7.3,1.7c-2.1,1-4.1,2.8-5.4,5
                l0.1,0.1l5.1,4.8c2.1-3.3,4.4-4.5,7.5-4.5c1.7,0,3,0.4,4.1,1.3c1,0.9,1.6,2,1.6,3.4v1.5c-2-0.6-3.9-0.9-5.8-0.9
                c-3.9,0-7.1,0.9-9.5,2.7c-2.4,1.8-3.6,4.5-3.6,7.9c0,3,1,5.3,3.1,7.1c2.1,1.7,4.6,2.6,7.6,2.6c3,0,5.8-1.2,8.4-3.3v2.6h7.8v-20.2
                C319.5,272,318.3,269,315.9,267z M301.9,284c0.9-0.6,2.2-0.9,3.8-0.9c1.9,0,3.9,0.4,6,1.1v3.1c-1.7,1.6-4,2.4-6.8,2.4
                c-1.4,0-2.4-0.3-3.2-0.9c-0.7-0.6-1.1-1.3-1.1-2.3C300.6,285.4,301,284.6,301.9,284z"/>
            <path id="XMLID_393_" style={{fill:'#0080FF'}} d="M349.9,267.6c-2.2-2.5-5.3-3.7-9.2-3.7c-3.1,0-5.7,0.9-7.6,2.7v-1.9h-7.7V296h7.9v-17.3
                c0-2.4,0.6-4.3,1.7-5.6c1.1-1.3,2.6-2,4.7-2c1.8,0,3.1,0.6,4.1,1.8c1,1.2,1.5,2.9,1.5,4.9V296h7.9v-18.2
                C353.2,273.5,352.1,270,349.9,267.6z"/>
            <path id="XMLID_320_" style={{fill:'#0080FF'}} d="M155.5,267L155.5,267c-2.4-2-5.7-3.1-9.8-3.1c-2.6,0-5.1,0.6-7.3,1.7c-2.1,1-4.1,2.8-5.4,5
                l0.1,0.1l5.1,4.8c2.1-3.3,4.4-4.5,7.5-4.5c1.7,0,3,0.4,4.1,1.3c1,0.9,1.6,2,1.6,3.4v1.5c-2-0.6-3.9-0.9-5.8-0.9
                c-3.9,0-7.1,0.9-9.5,2.7c-2.4,1.8-3.6,4.5-3.6,7.9c0,3,1,5.3,3.1,7.1c2.1,1.7,4.6,2.6,7.6,2.6c3,0,5.8-1.2,8.4-3.3v2.6h7.8v-20.2
                C159.1,272,157.9,269,155.5,267z M141.5,284c0.9-0.6,2.2-0.9,3.8-0.9c1.9,0,3.9,0.4,6,1.1v3.1c-1.7,1.6-4,2.4-6.8,2.4
                c-1.4,0-2.4-0.3-3.2-0.9c-0.7-0.6-1.1-1.3-1.1-2.3C140.2,285.4,140.6,284.6,141.5,284z"/>
            <path id="XMLID_235_" style={{fill:'#0080FF'}} d="M202,296.7c-12.7,0-23-10.3-23-23s10.3-23,23-23s23,10.3,23,23S214.7,296.7,202,296.7z
                 M202,258.8c-8.2,0-14.9,6.7-14.9,14.9s6.7,14.9,14.9,14.9s14.9-6.7,14.9-14.9S210.2,258.8,202,258.8z"/>
        </g>
    </g>
    </svg>

);

export default DigitalOceanIcon;
