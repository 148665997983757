import React from "react";

import { Icon } from '@types';

const ModernDesignIcon: Icon = ({ fill = '#c4c4c4', title = 'modern_design', ...props }) => (
<svg { ...props } id="b5708c2f-4f88-4c73-bc81-55ebbc869fb7" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="704" height="741.61701" viewBox="0 0 704 741.61701">
    <title>{ title }</title>
    <ellipse cx="352" cy="723.61701" rx="352" ry="18" fill="#e6e6e6" />
    <path d="M897.612,539.79412v4.35665s7.842,27.88262-1.74266,27.88262-13.07-27.01128-13.07-27.01128v-5.228Z" transform="translate(-248 -79.1915)" fill="#ffb8b8" />
    <path d="M723.34562,440.46229l1.74267-6.97066s9.58465-21.78329,17.42663-17.42663-3.48532,20.912-3.48532,20.912l-1.74267,4.35666Z" transform="translate(-248 -79.1915)" fill="#ffb8b8" />
    <polygon points="531.111 673.208 532.854 688.02 549.409 690.634 549.409 671.465 531.111 673.208" fill="#ffb8b8" />
    <polygon points="622.601 668.851 624.343 690.634 643.513 688.02 640.027 667.108 622.601 668.851" fill="#ffb8b8" />
    <polygon points="523.269 503.298 519.784 504.169 529.368 596.53 529.368 679.307 553.765 674.95 565.964 529.438 603.431 600.887 621.729 673.208 650.483 667.98 626.957 577.361 611.273 491.099 523.269 503.298" fill="#2f2e41" />
    <path d="M788.69551,761.98373s-7.842-5.228-10.456-1.74266-13.94131,20.912-13.94131,20.912-27.88262,28.754,0,27.88262c0,0,18.298,0,21.78329-10.456,0,0,.87134-5.228,6.97066-5.228s13.07-6.09933,13.07-9.58465-6.09933-21.08349-8.71332-18.38373S788.69551,761.98373,788.69551,761.98373Z" transform="translate(-248 -79.1915)" fill="#2f2e41" />
    <path d="M882.24179,761.98373s7.842-5.228,10.456-1.74266,13.94131,20.912,13.94131,20.912,27.88262,28.754,0,27.88262c0,0-18.298,0-21.7833-10.456,0,0-.87133-5.228-6.97065-5.228s-13.07-6.09933-13.07-9.58465,6.09932-21.08349,8.71332-18.38373S882.24179,761.98373,882.24179,761.98373Z" transform="translate(-248 -79.1915)" fill="#2f2e41" />
    <circle cx="525.4472" cy="333.82384" r="23" fill="#ffb8b8" />
    <polygon points="523.705 352.122 535.903 366.934 557.686 348.636 542.003 336.438 523.705 352.122" fill="#ffb8b8" />
    <path d="M778.23953,446.56161l.71231-6.448,24.46042-14.05445,33.22948-3.15293a7.8499,7.8499,0,0,1,8.55669,7.07752l12.33229,130.72235,4.35666,11.32731S785.21018,615.6,767.78355,583.36071l13.07-67.96389-27.01128-51.40857Z" transform="translate(-248 -79.1915)" fill="#d0cde1" />
    <path d="M837.49009,424.77831a7.15719,7.15719,0,0,0,1.55228-.44232,7.87558,7.87558,0,0,1,9.01377,2.74447L887.156,480.54355l14.81264,61.86456h-20.912l-13.94131-41.82393L844.46075,470.9589Z" transform="translate(-248 -79.1915)" fill="#d0cde1" />
    <polygon points="512.813 383.054 505.842 384.797 484.93 403.095 491.901 361.271 474.474 357.785 460.969 427.928 470.553 437.512 525.012 415.293 512.813 383.054" fill="#d0cde1" />
    <path d="M782.60636,412.94s-9.44763-13.87373-14.855-9.30557-20.567,13.52851-23.76128,6.436,9.08225-20.0864,18.34719-23.19274,25.53441-7.23457,30.63719,6.46319a59.901,59.901,0,0,1,2.99419,27.12436Z" transform="translate(-248 -79.1915)" fill="#2f2e41" />
    <ellipse cx="782.02377" cy="411.37859" rx="2.5" ry="4.5" transform="translate(-341.49098 212.7407) rotate(-19.97743)" fill="#ffb8b8" />
    <path d="M676,252.13875h-3.99878V142.59342A63.40185,63.40185,0,0,0,608.59917,79.1915H376.51273a63.40185,63.40185,0,0,0-63.402,63.40192V743.5676a63.40185,63.40185,0,0,0,63.402,63.40193H608.59917a63.40185,63.40185,0,0,0,63.40205-63.40193V330.115H676Z" transform="translate(-248 -79.1915)" fill="#3f3d56" />
    <path d="M611.15749,95.68648h-30.295A22.49484,22.49484,0,0,1,560.03536,126.677H427.0759a22.49484,22.49484,0,0,1-20.82714-30.99056H377.95319a47.3478,47.3478,0,0,0-47.34787,47.34774V743.12674a47.34781,47.34781,0,0,0,47.34787,47.34781h233.2043a47.34781,47.34781,0,0,0,47.34786-47.34781V143.03422A47.3478,47.3478,0,0,0,611.15749,95.68648Z" transform="translate(-248 -79.1915)" fill="#fff" />
    <rect x="196.31772" y="177.70431" width="82.65983" height="7.67616" fill={ fill } />
    <rect x="196.31772" y="204.25859" width="144.06914" height="7.67616" fill="#e6e6e6" />
    <rect x="196.31772" y="230.81286" width="143.92807" height="7.67616" fill="#e6e6e6" />
    <rect x="117.77811" y="177.81829" width="61.40931" height="61.40931" fill={ fill } />
    <rect x="196.31772" y="338.90375" width="82.65983" height="7.67616" fill={ fill } />
    <rect x="196.31772" y="365.45803" width="144.06914" height="7.67616" fill="#e6e6e6" />
    <rect x="196.31772" y="392.0123" width="143.92807" height="7.67616" fill="#e6e6e6" />
    <rect x="117.77811" y="339.01773" width="61.40931" height="61.40931" fill={ fill } />
    <rect x="196.31772" y="500.10319" width="82.65983" height="7.67616" fill={ fill } />
    <rect x="196.31772" y="526.65747" width="144.06914" height="7.67616" fill="#e6e6e6" />
    <rect x="196.31772" y="553.21174" width="143.92807" height="7.67616" fill="#e6e6e6" />
    <rect x="117.77811" y="500.21717" width="61.40931" height="61.40931" fill={ fill } />
</svg>
);

export default ModernDesignIcon;
