import React from "react";

import { Icon } from '@types';

const MobileWireframeIcon: Icon = ({ fill = '#c4c4c4', title = 'mobile_wireframe', ...props }) => (
<svg { ...props } id="b8ce0917-79ae-4875-bfb0-e0ef48cf483c" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="972.38479" height="563.40011" viewBox="0 0 972.38479 563.40011">
    <title>{ title }</title>
    <rect y="501.56206" width="963.50956" height="2.24072" fill="#3f3d56" />
    <path d="M238.48554,264.99408c-8.273-19.52026-29.3326-23.20685-29.3326-23.20685s-20.27728-5.40953-37.21148,20.35314c-15.784,24.01293-36.13851,46.51092-10.325,56.09565l6.81865-14.32023.818,16.48027a105.09488,105.09488,0,0,0,11.36384,1.72452c28.63218,2.90875,55.62248,7.74518,56.12236-2.60231C237.40385,305.76275,246.44586,283.77648,238.48554,264.99408Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <path d="M151.1317,421.31634l-1.84239,46.981s-19.34512,37.769-3.68478,36.84785,13.81794-36.84785,13.81794-36.84785l5.52718-44.21742Z" transform="translate(-113.8076 -168.29995)" fill="#ffb8b8" />
    <path d="M257.06926,413.02557l5.52718,46.981s16.58153,24.87229,3.68478,33.16306-12.89674-29.47828-12.89674-29.47828l-13.81794-47.9022Z" transform="translate(-113.8076 -168.29995)" fill="#ffb8b8" />
    <path d="M237.72414,500.53921l-16.58153,64.48373s-7.36957,25.79349-17.50272,38.69024l-3.68479,7.36957-14.73914-1.8424,10.13316-41.45382,6.44837-76.45929Z" transform="translate(-113.8076 -168.29995)" fill="#ffb8b8" />
    <path d="M237.72414,500.53921l-16.58153,64.48373s-7.36957,25.79349-17.50272,38.69024l-3.68479,7.36957-14.73914-1.8424,10.13316-41.45382,6.44837-76.45929Z" transform="translate(-113.8076 -168.29995)" opacity="0.1" />
    <path d="M181.56952,598.95586l25.19275,5.833L196.37936,629.265s4.22,14.65294.20014,22.41277-3.43977,21.83948-3.43977,21.83948l-5.45987-.85994s-13.25617,24.95614-28.91916,17.82642.99648-24.08941.99648-24.08941S173.3,639.61824,175.01988,628.6985,181.56952,598.95586,181.56952,598.95586Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <path d="M181.56952,598.95586l25.19275,5.833L196.37936,629.265s4.22,14.65294.20014,22.41277-3.43977,21.83948-3.43977,21.83948l-5.45987-.85994s-13.25617,24.95614-28.91916,17.82642.99648-24.08941.99648-24.08941S173.3,639.61824,175.01988,628.6985,181.56952,598.95586,181.56952,598.95586Z" transform="translate(-113.8076 -168.29995)" opacity="0.1" />
    <path d="M160.34367,486.72126,174.16161,555.811s-4.606,34.08425.92119,55.27177L180.61,631.34906H199.0339V556.73217s.9212-60.79894-1.84239-65.40493S160.34367,486.72126,160.34367,486.72126Z" transform="translate(-113.8076 -168.29995)" fill="#ffb8b8" />
    <path d="M202.71869,623.97949l-25.79349,1.8424,6.44837,25.79349s-6.44837,13.81794-3.68479,22.10871,0,22.1087,0,22.1087H185.216s9.212,26.71469,25.79349,22.10871,2.76359-23.9511,2.76359-23.9511-9.212-28.55708-9.212-39.61143S202.71869,623.97949,202.71869,623.97949Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <circle cx="95.35946" cy="112.07338" r="24.8723" fill="#ffb8b8" />
    <path d="M192.12493,290.04588s1.8424,31.32067-3.68478,33.16306,28.55708,0,28.55708,0-4.606-19.34512,0-24.87229S192.12493,290.04588,192.12493,290.04588Z" transform="translate(-113.8076 -168.29995)" fill="#ffb8b8" />
    <path d="M217.45783,318.14237s-23.9511-1.8424-28.55708,0S171.398,329.19672,171.398,329.19672l6.44837,58.03536,52.50818,11.05435,8.29077-11.97555,8.29076-17.50273-3.68478-22.1087-.9212-12.89675Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M166.792,332.8815s-11.97555,2.76359-12.89675,11.97555-.92119,32.24187,0,35.92665,0,5.52718-.92119,6.44838-2.76359-.9212-.9212,1.84239,1.84239,2.76359.9212,5.52718-3.68479-.9212-1.8424,3.68478,2.76359,5.52718,1.8424,6.44838-3.68479,22.1087-3.68479,22.1087l15.66034,2.76359L180.61,375.25653Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M238.64534,332.8815a9.81734,9.81734,0,0,1,8.29076,5.52718c2.76359,5.52718,10.13316,41.45383,9.212,44.21742s-2.76359-.9212-.9212,4.606,0,4.606.9212,8.29076-3.68479,0,0,3.68479,5.52717,4.606,3.68478,6.44837-4.606-1.84239-1.84239,1.84239,2.76359,9.212,2.76359,9.212l-22.10871,4.606-6.44837-51.587Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M163.9083,331.4167l4.52546-2.26273a3.87384,3.87384,0,0,1,5.21245,1.76308l20.78171,42.49709,23.02991,14.73913,16.58153-7.36957s11.97555-18.42392,8.29076-27.63588c-2.49831-6.24578-5.425-15.85546-7.0508-21.3964a1.65464,1.65464,0,0,1,2.509-1.83875l3.13985,2.09324a4.59319,4.59319,0,0,1,1.9502,2.89169l6.82133,32.98936-9.212,19.34512V405.656l6.44837,99.48919s-23.9511-3.68479-47.9022-8.29077-54.35057-8.29076-54.35057-8.29076,15.66034-81.06527,28.55708-94.88321c0,0,0-10.13316,1.84239-11.05435s3.68479-7.36957,3.68479-7.36957l-16.68621-38.84606A3.87383,3.87383,0,0,1,163.9083,331.4167Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <polygon points="120 89.602 98.895 78.547 69.749 83.07 63.719 109.703 78.73 109.125 82.923 99.341 82.923 108.964 89.85 108.698 93.87 93.12 96.382 109.703 121.005 109.2 120 89.602" fill="#2f2e41" />
    <ellipse cx="70.02656" cy="112.07338" rx="2.76359" ry="4.83628" fill="#ffb8b8" />
    <rect x="188.3847" width="622" height="319.00594" fill="#3f3d56" />
    <path d="M568.83638,344.03647H439.87632a1.46007,1.46007,0,0,1-1.46045-1.46045V290.99257a1.46006,1.46006,0,0,1,1.46045-1.46044H568.83638a1.45959,1.45959,0,0,1,1.46044,1.46044V342.576A1.4596,1.4596,0,0,1,568.83638,344.03647Zm-127.49961-2.92089H567.37593V292.453H441.33677Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M494.68445,377.88918H439.87632a1.46007,1.46007,0,0,1-1.46045-1.46045V354.50491a1.46007,1.46007,0,0,1,1.46045-1.46045h54.80813a1.45961,1.45961,0,0,1,1.46045,1.46045v21.92382A1.45961,1.45961,0,0,1,494.68445,377.88918Zm-53.34768-2.92089H493.224V355.96536H441.33677Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M568.83638,377.88918H514.02825a1.45961,1.45961,0,0,1-1.46045-1.46045V354.50491a1.45961,1.45961,0,0,1,1.46045-1.46045h54.80813a1.4596,1.4596,0,0,1,1.46044,1.46045v21.92382A1.4596,1.4596,0,0,1,568.83638,377.88918Zm-53.34769-2.92089h51.88724V355.96536H515.48869Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M567.22475,342.42485a1.46192,1.46192,0,0,1-.53341-.10127L439.34292,292.35176a1.4601,1.4601,0,0,1,1.06681-2.71837l127.34843,49.97182a1.46033,1.46033,0,0,1-.53341,2.81964Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M439.877,344.03647a1.46066,1.46066,0,0,1-.54338-2.81678l128.96076-51.58345a1.46061,1.46061,0,0,1,1.08393,2.71267L440.419,343.93236A1.45428,1.45428,0,0,1,439.877,344.03647Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M493.87864,377.08337a1.46923,1.46923,0,0,1-.53483-.10126l-53.19722-20.95685a1.46089,1.46089,0,0,1,1.07109-2.71837l53.19579,20.95685a1.46051,1.46051,0,0,1-.53483,2.81963Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M440.68213,377.08337a1.46014,1.46014,0,0,1-.49917-2.83247l53.1965-19.34379a1.46,1.46,0,1,1,.99835,2.744l-53.1965,19.34379A1.46013,1.46013,0,0,1,440.68213,377.08337Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M568.03056,377.08337a1.47455,1.47455,0,0,1-.51344-.09271l-51.58345-19.34379a1.46094,1.46094,0,0,1,1.02688-2.73549l51.58345,19.3438a1.46063,1.46063,0,0,1-.51344,2.82819Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M516.44711,375.47174a1.46027,1.46027,0,0,1-.47493-2.841l51.58345-17.73217a1.46,1.46,0,1,1,.94986,2.76116L516.922,375.39187A1.45616,1.45616,0,0,1,516.44711,375.47174Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M360.282,261.75216a21.90671,21.90671,0,1,1,21.90671-21.9067A21.93112,21.93112,0,0,1,360.282,261.75216Zm0-40.89252a18.98582,18.98582,0,1,0,18.98581,18.98582A19.00734,19.00734,0,0,0,360.282,220.85964Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M504.86622,261.75216a21.90671,21.90671,0,1,1,21.90671-21.9067A21.93067,21.93067,0,0,1,504.86622,261.75216Zm0-40.89252A18.98582,18.98582,0,1,0,523.852,239.84546,19.0078,19.0078,0,0,0,504.86622,220.85964Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M684.50123,261.75216a21.90671,21.90671,0,1,1,21.90671-21.9067A21.93066,21.93066,0,0,1,684.50123,261.75216Zm0-40.89252A18.98582,18.98582,0,1,0,703.487,239.84546,19.00781,19.00781,0,0,0,684.50123,220.85964Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M872.70352,261.75216a21.90671,21.90671,0,1,1,21.90671-21.9067A21.93066,21.93066,0,0,1,872.70352,261.75216Zm0-40.89252a18.98582,18.98582,0,1,0,18.98582,18.98582A19.0078,19.0078,0,0,0,872.70352,220.85964Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M500.99119,246.60716v-1.10532l1.41053-1.3706c3.39725-3.23181,4.93186-4.95183,4.95183-6.95566a2.3713,2.3713,0,0,0-2.63851-2.59715,4.5314,4.5314,0,0,0-2.82391,1.12386l-.57334-1.2679a5.83934,5.83934,0,0,1,3.76522-1.35063,3.74182,3.74182,0,0,1,4.07042,3.86647c0,2.45453-1.77992,4.43982-4.58244,7.13965l-1.06395.98267v.03993h5.97442v1.49468Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M359.797,234.87366h-.04064l-2.3119,1.24794-.34729-1.37059,2.9045-1.55458h1.53461v13.29805H359.797Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M874.01279,246.49377V242.874h-6.17837v-1.18661l5.93306-8.49028h1.94394v8.26493h1.86121v1.412h-1.86121v3.61974Zm0-5.0317v-4.43839q0-1.044.06276-2.088h-.06276c-.4079.77872-.73593,1.35063-1.10389,1.96533l-3.2532,4.51968v.04136Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M682.25208,244.4279a6.02877,6.02877,0,0,0,2.94514.83861c2.31333,0,3.02929-1.47328,3.0079-2.57717-.02-1.86265-1.69863-2.6599-3.43576-2.6599H683.7653v-1.35063h1.00406c1.30926,0,2.96653-.6746,2.96653-2.25057,0-1.064-.676-2.00526-2.33329-2.00526a4.86466,4.86466,0,0,0-2.65847.8814l-.47066-1.30927a6.3113,6.3113,0,0,1,3.47712-1.024c2.61854,0,3.80515,1.55457,3.80515,3.1719a3.30265,3.30265,0,0,1-2.45594,3.12913v.04136a3.46251,3.46251,0,0,1,2.96653,3.41579c0,2.12792-1.65727,3.99056-4.84771,3.99056a6.74683,6.74683,0,0,1-3.45716-.90137Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M752.85272,376.42865H623.89267a1.45961,1.45961,0,0,1-1.46045-1.46045V292.45293a1.4596,1.4596,0,0,1,1.46045-1.46044H752.85272a1.4596,1.4596,0,0,1,1.46045,1.46044V374.9682A1.45961,1.45961,0,0,1,752.85272,376.42865Zm-127.4996-2.9209H751.39228V293.91338H625.35312Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M729.77509,309.24808H650.91094a1.46045,1.46045,0,1,1,0-2.9209h78.86415a1.46045,1.46045,0,1,1,0,2.9209Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M729.77509,322.3921H650.91094a1.46045,1.46045,0,1,1,0-2.92089h78.86415a1.46044,1.46044,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M729.77509,335.53613H650.91094a1.46045,1.46045,0,1,1,0-2.9209h78.86415a1.46045,1.46045,0,1,1,0,2.9209Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M729.77509,348.68015H650.91094a1.46045,1.46045,0,1,1,0-2.92089h78.86415a1.46044,1.46044,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M729.77509,361.82417H650.91094a1.46044,1.46044,0,1,1,0-2.92089h78.86415a1.46045,1.46045,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M330.11854,295.04852a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M330.11854,311.11343a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M330.11854,327.17835a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M330.11854,343.24327a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M330.11854,359.30819a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M330.11854,375.37311a1.5009,1.5009,0,0,0,0-3,1.5009,1.5009,0,0,0,0,3Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M390.95134,295.00872H344.217a1.46045,1.46045,0,0,1,0-2.9209h46.73431a1.46045,1.46045,0,0,1,0,2.9209Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M390.95134,311.07363H344.217a1.46045,1.46045,0,1,1,0-2.92089h46.73431a1.46045,1.46045,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M390.95134,327.13855H344.217a1.46044,1.46044,0,1,1,0-2.92089h46.73431a1.46045,1.46045,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M390.95134,343.20347H344.217a1.46044,1.46044,0,1,1,0-2.92089h46.73431a1.46045,1.46045,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M390.95134,359.26839H344.217a1.46044,1.46044,0,1,1,0-2.92089h46.73431a1.46045,1.46045,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M390.95134,375.33331H344.217a1.46045,1.46045,0,1,1,0-2.92089h46.73431a1.46045,1.46045,0,1,1,0,2.92089Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M888.4233,382.00231H856.98518a7.85225,7.85225,0,0,1-7.8442-7.84278V293.26017a7.851,7.851,0,0,1,7.8442-7.84134h3.8137a1.46044,1.46044,0,0,1,1.35491,2.00668,1.57511,1.57511,0,0,0,1.45332,2.17071h17.92328a1.57447,1.57447,0,0,0,1.45617-2.16643,1.45978,1.45978,0,0,1,1.352-2.011h4.08469a7.85072,7.85072,0,0,1,7.84277,7.84134v80.89936A7.852,7.852,0,0,1,888.4233,382.00231Zm-31.43812-93.66259a4.92817,4.92817,0,0,0-4.92331,4.92045v80.89936a4.92846,4.92846,0,0,0,4.92331,4.92188H888.4233a4.92725,4.92725,0,0,0,4.92188-4.92188V293.26017a4.927,4.927,0,0,0-4.92188-4.92045h-2.41174a4.49911,4.49911,0,0,1-4.48117,4.17739H863.60711a4.49778,4.49778,0,0,1-4.47975-4.17739Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M826.16461,335.53613H785.27208a1.46045,1.46045,0,0,1,0-2.9209H822.639l-8.46033-8.46032a1.46029,1.46029,0,0,1,2.06516-2.06517l10.95336,10.95336a1.46032,1.46032,0,0,1-1.03258,2.493Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M815.94148,345.75926a1.46045,1.46045,0,0,1-1.03259-2.493L825.132,333.0431a1.46029,1.46029,0,0,1,2.06517,2.06516l-10.22313,10.22313A1.45576,1.45576,0,0,1,815.94148,345.75926Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M898.16058,458.45817H850.40973a167.01033,167.01033,0,0,1,0-56.93371h47.75085A102.443,102.443,0,0,0,898.16058,458.45817Z" transform="translate(-113.8076 -168.29995)" fill="#ff6584" />
    <path d="M633.03581,448.66955h47.75085a167.01033,167.01033,0,0,0,0-56.93371H633.03581A102.44286,102.44286,0,0,1,633.03581,448.66955Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M705.70512,464.68143H753.456a167.01033,167.01033,0,0,0,0-56.93371H705.70512A102.443,102.443,0,0,1,705.70512,464.68143Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M673.25258,408.32357H644.19531c-.50146,0-.908-.65321-.908-1.46045s.40657-1.46045.908-1.46045h29.05727c.50146,0,.908.65321.908,1.46045S673.754,408.32357,673.25258,408.32357Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M673.25258,421.87208H644.19531c-.50146,0-.908-.65321-.908-1.46045s.40657-1.46044.908-1.46044h29.05727c.50146,0,.908.65321.908,1.46044S673.754,421.87208,673.25258,421.87208Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M745.92189,430.49386H716.86462c-.50146,0-.908-.6532-.908-1.46044s.40658-1.46045.908-1.46045h29.05727c.50146,0,.908.65321.908,1.46045S746.42335,430.49386,745.92189,430.49386Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M745.92189,444.04238H716.86462c-.50146,0-.908-.65321-.908-1.46045s.40658-1.46045.908-1.46045h29.05727c.50146,0,.908.65321.908,1.46045S746.42335,444.04238,745.92189,444.04238Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M887.56545,416.94535H858.50819c-.50147,0-.908-.65321-.908-1.46045s.40657-1.46045.908-1.46045h29.05726c.50147,0,.908.65321.908,1.46045S888.06692,416.94535,887.56545,416.94535Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M887.56545,430.49386H858.50819c-.50147,0-.908-.6532-.908-1.46044s.40657-1.46045.908-1.46045h29.05726c.50147,0,.908.65321.908,1.46045S888.06692,430.49386,887.56545,430.49386Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M673.25258,435.4206H644.19531c-.50146,0-.908-.65321-.908-1.46045s.40657-1.46045.908-1.46045h29.05727c.50146,0,.908.65321.908,1.46045S673.754,435.4206,673.25258,435.4206Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M392.85759,449.90123h47.75086a167.01083,167.01083,0,0,0,0-56.93371H392.85759A102.44286,102.44286,0,0,1,392.85759,449.90123Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M433.07436,409.55525H404.0171c-.50146,0-.908-.65321-.908-1.46045s.40658-1.46044.908-1.46044h29.05726c.50147,0,.908.6532.908,1.46044S433.57583,409.55525,433.07436,409.55525Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M433.07436,423.10376H404.0171c-.50146,0-.908-.6532-.908-1.46044s.40658-1.46045.908-1.46045h29.05726c.50147,0,.908.65321.908,1.46045S433.57583,423.10376,433.07436,423.10376Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M433.07436,436.65228H404.0171c-.50146,0-.908-.65321-.908-1.46045s.40658-1.46044.908-1.46044h29.05726c.50147,0,.908.6532.908,1.46044S433.57583,436.65228,433.07436,436.65228Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M255.85759,539.90123h47.75086a167.01083,167.01083,0,0,0,0-56.93371H255.85759A102.44286,102.44286,0,0,1,255.85759,539.90123Z" transform="translate(-113.8076 -168.29995)" fill={ fill } />
    <path d="M296.07436,499.55525H267.0171c-.50146,0-.908-.65321-.908-1.46045s.40658-1.46044.908-1.46044h29.05726c.50147,0,.908.6532.908,1.46044S296.57583,499.55525,296.07436,499.55525Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M296.07436,513.10376H267.0171c-.50146,0-.908-.6532-.908-1.46044s.40658-1.46045.908-1.46045h29.05726c.50147,0,.908.65321.908,1.46045S296.57583,513.10376,296.07436,513.10376Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M296.07436,526.65228H267.0171c-.50146,0-.908-.65321-.908-1.46045s.40658-1.46044.908-1.46044h29.05726c.50147,0,.908.6532.908,1.46044S296.57583,526.65228,296.07436,526.65228Z" transform="translate(-113.8076 -168.29995)" fill="#fff" />
    <path d="M916.44972,433.98576s-29.33824,23.05147-14.66912,28.29044,24.09927-18.86029,24.09927-18.86029Z" transform="translate(-113.8076 -168.29995)" fill="#a0616a" />
    <path d="M917.49752,425.6034l-6.28677,10.47795s10.47794,0,12.57353,15.71691l8.38236-10.47794Z" transform="translate(-113.8076 -168.29995)" fill="#cfcce0" />
    <polygon points="943.047 496.201 944.094 527.634 931.521 534.969 926.282 531.826 927.33 496.201 943.047 496.201" fill="#a0616a" />
    <path d="M982.46076,656.11815l2.09559,33.52942L955.21811,702.2211l2.09559-17.81251s8.38235-13.62132,6.28676-28.29044Z" transform="translate(-113.8076 -168.29995)" fill="#a0616a" />
    <path d="M1027.51592,403.59972s16.7647,44.00736,19.90809,71.25,8.38235,99.54045,8.38235,99.54045l10.47795,93.2537-29.33825,4.19117-12.57353-94.30148-28.29044-93.2537-6.28677,93.2537-5.239,84.87133-27.24265,1.0478L955.21811,575.438,943.69237,463.324l6.28677-53.43751Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <path d="M1043.23283,698.02992s1.71855-17.5978,14.4806-7.75111l2.28411-1.679,10.47794,23.05148s12.57353,17.8125-9.43015,19.90809-19.90809-19.90809-19.90809-19.90809l-2.09559-9.43015,1.0478-6.17539Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <path d="M982.46076,677.074a16.64609,16.64609,0,0,1,8.38236,14.66912c0,10.47795-1.0478,11.52574-1.0478,11.52574l-15.71691,4.19118s-10.47795-1.0478-16.76471,5.239-37.7206,10.47794-37.7206,2.09559S945.788,692.79095,945.788,692.79095s7.77909-13.1373,13.3197-11.80762,7.63619,3.42526,7.63619,4.47306-3.14339,8.38235-3.14339,8.38235S981.413,689.64757,982.46076,677.074Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <circle cx="866.55757" cy="80.22622" r="26.19486" fill="#a0616a" />
    <path d="M999.74937,255.33683l11.52573,26.19486-31.43382,45.05515s-2.09559-49.24633-8.38236-57.62868S999.74937,255.33683,999.74937,255.33683Z" transform="translate(-113.8076 -168.29995)" fill="#a0616a" />
    <polygon points="871.273 141.522 876.512 127.901 880.703 128.949 885.942 124.757 893.276 101.706 903.754 109.041 917.376 246.302 832.504 248.397 844.03 153.048 854.508 125.805 862.071 115.807 867.081 131.044 871.273 141.522" fill="#cfcce0" />
    <path d="M1009.17952,368.49862l3.20623-94.78139s6.22391.4799,8.3195,3.62328,36.6728,9.43015,36.6728,9.43015l-6.28676,97.44487s26.19486,77.53678,16.76471,81.728-26.19486,3.14339-26.19486,3.14339Z" transform="translate(-113.8076 -168.29995)" fill="#575a89" />
    <path d="M958.36149,356.449l11.1153-63.87893-24.73662,16.72819,6.28676,46.10295L945.788,386.835s-19.90809,86.96692-13.62132,90.1103,13.62132,0,13.62132,0l12.57353-88.01472Z" transform="translate(-113.8076 -168.29995)" fill="#575a89" />
    <path d="M952.07473,307.20265l-7.33456,2.09559L941.59678,351.21l-5.239,36.6728L911.21075,432.938s12.57353-8.38235,17.8125,15.71692l27.69428-48.21652Z" transform="translate(-113.8076 -168.29995)" fill="#575a89" />
    <path d="M1033.80268,425.6034s-29.33824,23.05148-14.66912,28.29045,24.09927-18.8603,24.09927-18.8603Z" transform="translate(-113.8076 -168.29995)" fill="#a0616a" />
    <polygon points="937.808 268.829 928.377 280.355 915.804 258.351 921.043 248.921 937.808 268.829" fill="#cfcce0" />
    <path d="M1049.5196,290.43794l8.38235-4.19118s6.28677,8.38236,7.33456,15.71692,6.28677,16.76471,6.28677,22.00368,14.66912,54.4853,14.66912,54.4853L1052.663,446.55929s-7.33456-25.14706-23.05148-27.24265l26.19486-36.6728L1041.13724,356.449Z" transform="translate(-113.8076 -168.29995)" fill="#575a89" />
    <path d="M1002.29425,223.5681l4.93964-1.97756s-10.32832-11.37091-24.69829-10.38209l4.04164-4.44953s-9.87929-3.95506-18.86045,6.427c-4.72123,5.45762-10.18373,11.8728-13.589,19.09941h-5.29l2.20785,4.86145-7.7275,4.86145,7.93153-.87322a27.16623,27.16623,0,0,0-.21524,8.06362,10.43319,10.43319,0,0,0,3.82054,6.82522h0S960.981,243.34353,960.981,241.366v4.94388s4.93964-4.44947,4.93964-7.41577l2.69433,3.46071,1.34716-5.43829,16.61513,5.43829-2.69432-4.44947,10.32832,1.48315-4.04164-5.43829s11.67549,6.427,12.12465,11.86532c.449,5.43821,3.86647,10.59127,3.86647,10.59127S1015.31693,230.98387,1002.29425,223.5681Z" transform="translate(-113.8076 -168.29995)" fill="#2f2e41" />
    <ellipse cx="888.03735" cy="76.03505" rx="2.93382" ry="3.66728" fill="#a0616a" />
</svg>
);

export default MobileWireframeIcon;
